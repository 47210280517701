<template>
    <transition>
        <div ref="contentsWrap" class="contents-wrap" id="contentsWrap">
            <div class="contents-box">
                <div class="conts-row">
                    <div class="conts-box">
                        <div class="conts-title">서비스 템플릿 명 *</div>
                        <div class="conts-input">
                            <DxTextBox
                                v-model="formData.svctpNm"
                                :max-length="limitNumberTexts.maxLengths.svctpNm"
                                :styling-mode="stylingMode"
                                class="mar_ri10"
                                :width="350"
                                @key-up="$_checkLimitTextLength($event, formData, limitNumberTexts, 'svctpNm')"
                            >
                                <DxValidator>
                                    <DxRequiredRule message="서비스 템플릿 명은 필수입니다."/>
                                </DxValidator>
                            </DxTextBox>
                            {{ limitNumberTexts.textLengths.svctpNm ? limitNumberTexts.textLengths.svctpNm : (formData.svctpNm ? formData.svctpNm.length : 0) }}/{{
                                limitNumberTexts.maxLengths.svctpNm
                            }}자
                        </div>
                    </div>
                </div>

                <div class="conts-row">
                    <div class="conts-box">
                        <div class="conts-title">설명</div>
                        <div class="conts-input">
                            <DxTextArea
                                v-model="formData.description"
                                :max-length="limitNumberTexts.maxLengths.description"
                                :styling-mode="stylingMode"
                                class="mar_ri10 alB"
                                :width="350"
                                :height="85"
                                @key-up="$_checkLimitTextLength($event, formData, limitNumberTexts, 'description')"
                            >
                            </DxTextArea>
                            {{ limitNumberTexts.textLengths.description ? limitNumberTexts.textLengths.description : (formData.description ? formData.description.length : 0) }}/{{
                                limitNumberTexts.maxLengths.description
                            }}자
                        </div>
                    </div>
                </div>

                <div class="conts-row">
                    <div class="conts-box">
                        <div class="conts-title">순서</div>
                        <div class="conts-input">
                            <DxNumberBox
                                v-model="formData.svctpOrd"
                                :min="1"
                                :max="getMaxOrd"
                                :show-spin-buttons="true"
                                :width="350"
                                :styling-mode="this.stylingMode"
                            />
                        </div>
                    </div>
                </div>

                <div class="conts-row">
                    <div class="conts-box">
                        <div class="conts-title">사용여부</div>
                        <div class="conts-input">
                            <DxSwitch
                                @value-changed="onChangedViewFl($event)"
                                :value="getViewFl"
                                switched-off-text="OFF"
                                switched-on-text="ON"
                            />
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </transition>
</template>

<script>
import {DxTextBox} from 'devextreme-vue/text-box';
import DxTextArea from 'devextreme-vue/text-area';
import {DxNumberBox} from 'devextreme-vue/number-box';
import {DxValidator, DxRequiredRule} from 'devextreme-vue/validator';
import {DxSelectBox} from 'devextreme-vue/select-box';
import DxSwitch from "devextreme-vue/switch";
import {number} from "sockjs-client/lib/utils/random";

export default {
    components: {
        DxSwitch,
        DxNumberBox,
        DxTextBox,
        DxTextArea,
        DxValidator,
        DxRequiredRule,
        DxSelectBox
    },
    props: {
        templateContent: Object,    // Template Object
        maxOrd: Number,
        method: String,             // Template Method ex) INSERT, UDPATE
    },
    watch: {},
    data() {
        return {
            config: {
                api: {
                    templatesInsert: 'CC_IVR_SVC_TP_MERGE',
                    templatesUpdate: 'CC_IVR_SVC_TP_MERGE',
                    channelIvrDnisGet: 'CC_IVR_DNIS_LIST',
                },
            },
            stylingMode: 'outlined',    //[outlined, filled, underlined]
            formData: {             // Add/Update 시 사용할 객체
                svctpNm: '',
                description: '',
                svctpOrd: '',
                regId: '',
                viewFl: 'Y'
            },
            formFormat: {
                svctpNmSize: 30,
                descriptionSize: 200,
            },
            limitNumberTexts: {
                textLengths: {},
                maxLengths: {
                    svctpNm: 30,
                    description: 200,
                },
            },
        }
    },
    computed: {
        getMaxOrd() {
            if (this.method === 'INSERT') {
                return this.maxOrd + 1;
            } else if (this.method === 'UPDATE') {
                return this.maxOrd;
            }
        },
        getViewFl() {
            return this.formData.viewFl === 'Y' ? true : false;
        },
    },
    methods: {
        /**
         * @description : 메서드 타입에 따른 API actionNm 반환
         * @param : methodType
         */
        getAPIActionNmByMethod(type) {
            let url = '';
            switch (type) {
                case "INSERT" :
                    url = this.config.api.templatesInsert;
                    break;
                case "UPDATE" :
                    url = this.config.api.templatesUpdate;
                    break;
            }
            return url;
        },
        // 시나리오 객체 초기화
        initTemplate() {
            Object.keys(this.formData).forEach(key => this.formData[key] = '');
        },
        onChangedViewFl(e) {
            if (e.value)
                this.formData.viewFl = 'Y'
            else
                this.formData.viewFl = 'N'
        },
        /** @description : 라이프사이클 creaed시 호출되는 메서드 */
        createdData() {
            // 상위 탭에 대한 객체 생성
            if (this.method === 'INSERT') {           // props 로 시나리오 객체가 넘어오지 않을 경우 Insert
                this.initTemplate();
                this.formData.regId = this.$store.getters.getLoginId;
                this.formData.viewFl = 'Y';
                this.formData.svctpOrd = this.maxOrd + 1;
            } else if (this.method === 'UPDATE') {     // update 일경우 객체 속성 값 매칭
                this.formData = this.$_commonlib.cloneObj(this.templateContent);
                this.formData.editId = this.$store.getters.getLoginId;
            }
            this.formData.table = 'template';
        },
        /** @description : 라이프사이클 mounted 호출되는 메서드 */
        mountedData() {
            this.$_eventbus.$on('ModalTemplate:onSaveData', async (e, resolve) => {
                if (!e.validationGroup.validate().isValid) {
                    return false;
                }

                if (this.method === "UPDATE" && this.formData.viewFl === 'N') {
                    let checkIvrDnisByTemplate = false;
                    let payload = {
                        actionname: this.config.api.channelIvrDnisGet,
                        data: {params: {svctpId: this.formData.id}},
                    }

                    let res = await this.CALL_CC_API(payload);
                    if (res.status === 200) {
                        if (res.data.header.resCode === "success") {
                            if (res.data.data.length > 0) {
                                checkIvrDnisByTemplate = true;
                            }
                        } else {
                            this.$_Toast('작업 실패');
                            return false;
                        }
                    }

                    if (checkIvrDnisByTemplate) {
                        this.$_Toast('IVR Dnis에 할당된 템플릿은 미표시 처리할 수 없습니다.');
                        return false;
                    }
                }

                let data = {
                    data: [this.formData]
                };

                if (await this.$_Confirm('템플릿 정보를 저장 하시겠습니까?')) {
                    // INSERT 외에는 모두 UPDATE API
                    let params = {};
                    if (this.method === 'INSERT' && this.maxOrd + 1 !== data.data[0].svctpOrd) {
                        params = {isReordFl: true};
                    }
                    let url = this.getAPIActionNmByMethod(this.method);
                    let payload = {
                        actionname: url,
                        data: {...data, ...params},
                        loading: true,
                    }
                    let res = await this.CALL_CC_API(payload);
                    if (res.status === 200) {
                        if (res.data.header.resCode === "success") {
                            if (this.$_Toast('템플릿 정보가 저장되었습니다.')) {
                                resolve(200);
                            }
                        } else {
                            this.$_Toast('작업 실패');
                        }
                    }
                }
            });
        },
        /** @description : 라이프사이클 beforeDestroyed 호출되는 메서드 */
        beforeDestroyedData() {
            this.$_eventbus.$off('ModalTemplate:onSaveData');
        },
    },
    created() {
        this.createdData();
    },
    mounted() {
        this.mountedData();
    },
    beforeDestroy() {
        this.beforeDestroyedData();
    },
}
</script>

<style scoped>
.contents-title-box {
    height: 40px;
    position: relative;
    border-bottom: 1px solid #CCC;
    line-height: 40px;
}

.contents-title-box .contents-title {
    height: 40px;
    padding-left: 5px;
    display: inline-block;
    font-size: 0.9em;
}

.contents-box {
    width: 100%;
    padding: 20px 10px;
    background-color: #FFF;
}

.conts-row:not(:last-child) {
    margin-bottom: 20px;
}

.conts-row .conts-box {
    display: inline-block;
}

.conts-row .conts-box > div {
    display: inline-block;
}

.conts-row .conts-box .conts-title {
    width: 120px;
    display: inline-block;
    vertical-align: top;
}

.conts-row .conts-box .conts-input {
    display: -webkit-inline-box;
}

.conts-row .conts-box .conts-input .limitLength-box {
    margin-left: 5px;
    display: inline-block;
    font-size: 0.9em;
}
</style>